import logo from './logo.svg';
import './App.css';
import './css/bootstrap.min.css';
import './css/jumbotron.css';
function App() {
  return (
    <>
      <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <a class="navbar-brand" href="#">Krish Soft</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarsExampleDefault">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">About Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Portfolio</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Contact Us</a>
            </li>

          </ul>

        </div>
      </nav>

      <main role="main">

        <div class="jumbotron">
          <div class="container">
            <h1 class="display-3">Welcome To <b>Krish Soft!</b></h1>
            <p>Innovating Today for a Better Tomorrow ...</p>
            <p><a class="btn btn-primary btn-lg" href="#" role="button">Learn more &raquo;</a></p>
          </div>
        </div>

        <div class="container">

          <div class="row">
            <div class="col-md-4">
              <h2>Company Overview</h2>
              <p>Krish Soft is a dynamic and innovative software development company committed to delivering top-tier technology solutions. With a focus on cutting-edge software development, we aim to transform business processes and drive efficiency, productivity, and growth for our clients. Our expertise spans various industries, providing tailored solutions that meet the unique needs of each business.</p>
              <p><a class="btn btn-secondary" href="#" role="button">View details &raquo;</a></p>
            </div>
            <div class="col-md-4">
              <h2>Our Vision</h2>
              <p>At Krish Soft, our vision is to be a global leader in software innovation. We strive to harness the power of technology to create solutions that not only solve today’s challenges but also anticipate and address the needs of tomorrow. Our goal is to empower businesses to achieve their full potential through the strategic application of software solutions.</p>
              <p><a class="btn btn-secondary" href="#" role="button">View details &raquo;</a></p>
            </div>
            <div class="col-md-4">
              <h2>Our Mission</h2>
              <p>Our mission is to deliver high-quality, reliable, and scalable software solutions that drive business success. We are dedicated to continuous improvement, leveraging the latest technologies and methodologies to provide our clients with the best possible outcomes. Customer satisfaction and excellence in service are at the core of everything we do.</p>
              <p><a class="btn btn-secondary" href="#" role="button">View details &raquo;</a></p>
            </div>

          </div>
          <hr />
        </div>

      </main>

      <footer class="container">
        <p>&copy; Krish Soft 2014-2024</p>
      </footer>
    </>
  );
}

export default App;
